import getIcon from '@/lib/helpers/getIcon';
import now from '@/lib/helpers/now';
import getTaxYear from '@/lib/helpers/getTaxYear';
import uuid from '@/lib/helpers/uuid';
import hasPermission from '@/lib/helpers/hasPermission';
import hasRole from '@/lib/helpers/hasRole';
import getMask from '@/lib/helpers/getMask';
import getValidator from '@/lib/helpers/getValidator';
import getAlertType from '@/lib/helpers/getAlertType';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import removeNull from '@/api/lib/helpers/removeNull';
import toDatetime from '@/lib/helpers/toDatetime';
import toDate from '@/lib/helpers/toDate';
import toIso8601 from '@/lib/helpers/toIso8601';
import wait from '@/lib/helpers/wait';
import timeUntil from '@/lib/helpers/timeUntil';
import pageErrorRedirect from '@/lib/helpers/pageErrorRedirect';
import getQueryParam from '@/lib/helpers/getQueryParam';
import replaceQueryParam from '@/lib/helpers/replaceQueryParam';
import copyToClipboard from '@/lib/helpers/copyToClipboard';
import clone from '@/lib/helpers/clone';
import setBreadcrumbs from '@/lib/helpers/setBreadcrumbs';
import hideBreadcrumbs from '@/lib/helpers/hideBreadcrumbs';
import showBreadcrumbs from '@/lib/helpers/showBreadcrumbs';
import isEmailMatch from '@/lib/helpers/isEmailMatch';
import money from '@/lib/money';
import Auth from '@/models/Auth';
import ErrorService from '@/services/ErrorService';

export default {
    data: () => ({
        isMounted: false,
        uuid: null,
        $delayEmit: () => {},
        $delay: () => {},
        preventEmitEvent: () => false
    }),
    mounted() {
        this.isMounted = true;

        if (this._.isNil(this.uuid)) {
            this.uuid = uuid();
        }

        this.$delayEmit = (event, data, timeout = 300, prevent = false) => {
            return this._.debounce(() => {
                this.emitEvent(event, data, prevent);
            }, timeout)();
        };

        this.$delay = (callback, timeout = 300) => {
            return this._.debounce(() => {
                callback();
            }, timeout)();
        };
    },
    computed: {
        current_user_id() {
            return Auth().user_id;
        },
        current_user() {
            return Auth().user;
        },
        colours() {
            return Auth().branding().colours;
        },
        home_route() {
            return Auth().home_route;
        },
        default_colours() {
            return {
                primary: '#5abdf9',
                primaryBackground: '#5ABDF9',
                primaryLight: '#DFEAF1',
                primaryText: '#0670B1'
            };
        }
    },
    methods: {
        emitEvent(event, data = null, prevent = false) {
            if (prevent) return;
            this.$emit(event, data);
        },
        log(...v) {
            console.log(...v);
        },
        Auth,
        getIcon,
        now,
        hasPermission,
        hasRole,
        getTaxYear,
        getMask,
        getValidator,
        getAlertType,
        getFilterOperator,
        removeNull,
        toDatetime,
        toDate,
        toIso8601,
        money,
        wait,
        timeUntil,
        pageErrorRedirect,
        getQueryParam,
        replaceQueryParam,
        copyToClipboard,
        $clone: clone,
        setBreadcrumbs,
        hideBreadcrumbs,
        showBreadcrumbs,
        isEmailMatch,
        handleError(error, data = {}) {
            return ErrorService.onError(error, data);
        },
        toDecimal(value, { precision = 2, defaultValue = null, callback = null }) {
            if (['', null, undefined, NaN].includes(value)) {
                return defaultValue;
            }

            const decimal = this.money(value, { precision });

            if (String(value).startsWith('-0') && decimal.value === 0) {
                return value;
            }

            if (callback) {
                return callback(decimal);
            }

            return decimal;
        },
        findSlot(name = 'default') {
            return !!this.$slots[`${name}`] || !!this.$scopedSlots[`${name}`];
        },
        getParent(name) {
            let parent = this.$parent;

            while (typeof parent !== 'undefined') {
                if (parent.$options.name === name) {
                    return parent;
                } else {
                    parent = parent.$parent;
                }
            }

            return false;
        },
        findElement(selector) {
            return this.$el.querySelector(selector);
        },
        findParentElement(selector) {
            return this.$el.closest(selector);
        },
        getWidth($el) {
            try {
                return $el.getBoundingClientRect().width;
            } catch (error) {
                return null;
            }
        },
        isAdviser() {
            return Auth().is_adviser;
        },
        isProvider() {
            return Auth().is_provider;
        },
        isCompany() {
            return Auth().is_company;
        },
        isInvestor() {
            return Auth().is_investor;
        },
        isIndividualInvestor() {
            return Auth().is_individual_investor;
        },
        isXOInvestor() {
            return Auth().is_xo_investor;
        },
        isAdvisedInvestor() {
            return Auth().is_advised_investor;
        },
        isInvestorAuthorised() {
            return Auth().is_investor_authorised;
        },
        isOwner() {
            return Auth().is_owner;
        },
        isSuperuser() {
            return Auth().is_superuser;
        },
        isGrowthInvest() {
            return Auth().is_gi;
        },
        getOrganisationId() {
            return Auth().organisation_id;
        },
        getOrganisation() {
            return Auth().organisation;
        },
        getAuthInvestor() {
            return Auth().investor;
        },
        getAuthAdviser() {
            return Auth().adviser;
        },
        findRef(field, $refs = null) {
            let $ref = null;

            if ($refs === null) {
                $refs = this.$refs;
            }

            if (field in $refs) {
                return $refs[`${field}`];
            }

            for (const key in $refs) {
                if (!$refs[`${key}`]) {
                    continue;
                }

                if ('$refs' in $refs[`${key}`]) {
                    $ref = this.findRef(field, $refs[`${key}`].$refs);
                }
            }

            return $ref || null;
        },
        openMediaLibrary(collection, onSelected = () => {}, multiple = false, preselected = null, heading = null) {
            this.$store.dispatch('app/setMediaLibrary', {
                collection,
                onSelected,
                multiple,
                preselected,
                heading
            });
            this.$store.dispatch('app/setMediaLibraryVisibility', true);
        },
        closeMediaLibrary() {
            this.$store.dispatch('app/resetMediaLibrary');
        },
        openModal(key, props = {}, callback = () => {}) {
            this.$store.dispatch('app/setModal', { key, props, callback });
        },
        closeModal() {
            this.$store.dispatch('app/resetModal');
        },
        //
        setAppLoading(active = true, title = null, text = null, progress = null, buttons = []) {
            this.$store.dispatch('app/setLoading', { active, title, text, progress, buttons });
        },
        setAppLoadingTitle(title) {
            this.$store.dispatch('app/setLoadingTitle', title);
        },
        setAppLoadingText(text) {
            this.$store.dispatch('app/setLoadingText', text);
        },
        setAppLoadingProgress(progress) {
            this.$store.dispatch('app/setLoadingProgress', progress);
        },
        setAppLoadingButtons(buttons) {
            this.$store.dispatch('app/setLoadingButtons', buttons);
        },
        //
        hasAppProgressQueue() {
            return this.$store.getters['app/has_progress_queue'];
        },
        setAppProgressQueue(active = true, title = null, items = []) {
            this.$store.dispatch('app/setProgressQueue', { active, title, items });
        },
        setAppProgressQueueTitle(title) {
            this.$store.dispatch('app/setProgressQueueTitle', title);
        },
        setAppProgressQueueItems(items) {
            this.$store.dispatch('app/setProgressQueueItems', items);
        },
        prependAppProgressQueueItems(items) {
            this.$store.dispatch('app/prependProgressQueueItems', items);
        },
        updateAppProgressQueueItem(id, data) {
            this.$store.dispatch('app/updateProgressQueueItem', { id, data });
        },
        //
        hasShortcutKey(key) {
            return this.$store.getters['keyboard/has'](key);
        },
        hasShortcutKeys(keys) {
            return this.$store.getters['keyboard/only'](keys);
        },
        go(url = null) {
            if (typeof url === 'object') {
                try {
                    const route = this.$router.resolve(url);

                    if (!route) {
                        return;
                    }

                    url = route.href;
                } catch (error) {
                    return;
                }
            }

            if (url === null) {
                return;
            }

            if (this.hasShortcutKey('Control')) {
                window.open(url, '_blank');
                return;
            }

            this.$router.push(url);
        },
        hideFreshdeskWidget() {
            if (typeof window.FreshworksWidget === 'function') {
                window.FreshworksWidget('hide');
            }
        },
        showFreshdeskWidget() {
            if (typeof window.FreshworksWidget === 'function') {
                window.FreshworksWidget('show');
            }
        }
    },
    beforeDestroy() {
        this.isMounted = false;
    }
};
